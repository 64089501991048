@import '../variables/variables.scss';

/** FLASH SUCCESS SENDING MESSAGE **/
.flash {font-family: $montserrat; font-weight: 600; opacity: 1; transition: 2s; display: flex; justify-content: center; align-items: center; position: fixed; width: 350px; height: 100px; padding: 10px 20px; border-radius: 15px; top: 100px; right: 20px; background: #28a745; color: #fff; text-align: center; line-height: 27px; transition: height .4s; z-index: 9999;}
.flash--visible {opacity: 0; visibility: hidden; transition: 2s;}

/** RESPONSIVE **/
@media (max-width:768px) {
    .flash {width: 60%; top: 5%; right: 15%; left: 15%; font-size: 15px; line-height: 21px;}
}
