@import '../variables/variables.scss';

/** THINK & CREATE **/
#get-in-touch {display: flex; flex-direction: column; align-items: center; background-color: $yellow; padding: 80px;}
#get-in-touch.strategy-color {background-color: $strategy-color;}
#get-in-touch.creation-color {background-color: $creation-color;}
#get-in-touch.print-color {background-color: $print-color;}
#get-in-touch.identity-color {background-color: $identity-color;}
#get-in-touch.seo-color {background-color: $seo-color;}
#get-in-touch.community-color {background-color: $community-color;}
#get-in-touch .get-in-top {width: 100%; font-family: $chicle; color: $white; font-size: 200px; letter-spacing: 2px; text-align: center; font-style: italic; text-shadow: 8px 8px $black; opacity: 0.4;}
#get-in-touch .get-in-bottom {display: flex; flex-direction: column; align-items: center; z-index: 1; margin-top: -40px;}
#get-in-touch .get-in-bottom h2 {font-family: $montserrat; font-size: 36px; font-weight: 900;}
#get-in-touch .get-in-bottom a {background-color: #141414; width: 420px; padding: 15px 0px; border: 5px solid #141414; border-radius: 50px; font-family: $montserrat; color: $white; font-size: 48px; font-weight: 900; line-height: 52px; letter-spacing: -1px; text-decoration: none; text-align: center; margin-top: 10px; transition: .3s}
#get-in-touch .get-in-bottom a:hover {background-color: #ffffff6b; color: #141414; border: 5px solid #141414; transition: .3s;}

@media (max-width:768px) {
    #get-in-touch {padding: 40px;}
    #get-in-touch .get-in-top {width: 75%; font-size: 100px; line-height: 90px;}
    #get-in-touch .get-in-bottom h2 {font-size: 27px; text-align: center; margin-top: 70px;}
    #get-in-touch .get-in-bottom a {width: 220px; font-size: 27px; padding: 0;}
}
