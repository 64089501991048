@import '../variables/variables.scss';

.top-contact {position: sticky; position: -webkit-sticky; top: 0; z-index: 101; height: 26px; display: grid; grid-template-columns: repeat(4, auto); align-items: center; justify-items: center; padding: 5px 50px; background-color: $white; font-family: $montserrat; font-size: 16px; font-weight: 600;}
.top-contact .address {font-size: 20px; font-weight: 800;}
.top-contact a {text-decoration: none; color: $orange;}
// .top-contact a:hover {text-decoration: underline;}
.top-contact .link a {display: flex; align-items: center; color: $black; transition: 0s;}
.top-contact .link a:hover {color: $orange; transition: 0s;}
.top-contact .link a:hover svg path {fill: $orange; transition: 0s;}
.top-contact .link a svg {height: 15px; width: 25px; margin-right: 5px; transform: skew(-15deg, 0deg); transition: 0s;}
.top-contact .block-right {display: flex; display: -webkit-flex; justify-content: space-between; align-items: center;}
.top-contact .block-right .schedules {font-size: 13px; line-height: 13px; margin: 0 50px 0 0;}
.top-contact .block-right .social {display: flex; display: -webkit-flex; width: 100px;}
.top-contact .block-right .social a {margin: 0 5px;}
.top-contact .block-right .social a:hover {text-decoration: none;}
.top-contact .block-right .social a svg {height: 25px;}
.top-contact .block-right .social a svg:hover path {fill: $orange;}

.header {display: flex; align-items: center; justify-content: space-between; background-color: #141414; position: sticky; position: -webkit-sticky; top: 36px; z-index: 101; height: 81px;}
.block-up-left {display: flex; justify-content: center; width: 15%; padding-left: 36px;}
.block-up-left svg {height: 81px;}
.block-up-left .top-cwa {fill:#FFFFFF;}
.top-navigation {display: flex; justify-content: flex-end; margin-right: 4%; width: 70%;}
.top-navigation ul {margin: 0px; padding: 0px; list-style: none;}
.top-navigation a {font-family: $oswald; font-size: 24px; font-weight: 400; letter-spacing: .9px; color: $white; padding: 1em; display: block; text-align: center; text-decoration: none;}
.menu a.c-link:hover {color: $yellow;}
.top-navigation .menu {display: flex; flex-direction : row; justify-content: center; align-items: center;}
li.header-contact {display: flex; background-color: $white; border-radius: 40px; height: 36px; margin-left: 10px; border: 1px solid $white;}
li.header-contact a {align-self: center; color: #000;}
li.header-contact:hover {border: 1px solid $yellow; box-shadow: inset 400px 0 0 0 #141414; transition: .8s;}
li.header-contact a:hover {color: $yellow;}


/** MOBILE MENU **/

.mobile-header {z-index: 102; display: flex; margin: 0 auto; width: 100%; max-width: 100%; box-shadow: none; background-color: #fff; position: fixed; bottom:0; height: 60px!important; overflow: hidden; box-shadow: 0 5px 10px -3px rgba(14,13,26,.12), 0 3px 16px 2px rgba(7,48,114,.12), 0 8px 12px 1px rgba(34,0,51,.04);}
.main {margin: 0 auto; display: block; height: 100%; margin-top: 60px;}
.mainInner{display: table; height: 100%; width: 100%; text-align: center;}
.mainInner div{display:table-cell; vertical-align: middle; font-size: 3em; font-weight: bold; letter-spacing: 1.25px;}
#sidebarMenu {z-index: 101; display: flex; flex-direction: column; justify-content: space-between; height: 100%; position: fixed; right: 0; width: 100vw; transform: translateX(100vw); transition: transform 350ms ease-in-out; background-color: #fff;}
.sidebarMenuInner{margin:0; padding:25px; border-top: 1px solid rgba(255, 255, 255, 0.10);}
.sidebarMenuInner li{font-family: $montserrat; font-size: 21px; margin: 5%; list-style: none; color: #141414; text-transform: uppercase; font-weight: bold; cursor: none;}
.sidebarMenuInner li.title { font-size: 36px; font-weight: 900; text-transform: uppercase;}
.sidebarMenuInner li span{display: block; font-size: 14px; color: rgba(255, 255, 255, 0.50);}
.sidebarMenuInner li a{color: #141414; text-transform: uppercase; font-weight: bold; cursor: pointer; text-decoration: none;}
input[type="checkbox"]:checked ~ #sidebarMenu {transform: translateX(0);}
input[type=checkbox] {transition: all 0.3s; box-sizing: border-box; display: none;}
.sidebarIconToggle {transition: all 0.3s; box-sizing: border-box; cursor: pointer; position: fixed; z-index: 102; height: 100%; width: 100%; bottom: 18px; right: 25px; height: 25px; width: 25px;}
.spinner {transition: all 0.3s; box-sizing: border-box; position: absolute; height: 3px; width: 100%; background-color: #141414;}
.horizontal {transition: all 0.3s; box-sizing: border-box; position: relative; float: left; margin-top: 3px;}
.diagonal.part-1 {position: relative; transition: all 0.3s; box-sizing: border-box; float: left;}
.diagonal.part-2 {transition: all 0.3s; box-sizing: border-box; position: relative; float: left; margin-top: 3px;}
input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {transition: all 0.3s; box-sizing: border-box; opacity: 0;}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {transition: all 0.3s; box-sizing: border-box; transform: rotate(135deg); margin-top: 8px;}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {transition: all 0.3s; box-sizing: border-box; transform: rotate(-135deg); margin-top: -9px;}
#sidebarMenu .contact-block {display:flex; flex-direction: column; align-items: center; font-family: $montserrat; color: #fff; font-weight: 500; line-height: 36px; background: #141414; padding: 5%; width: 70%; margin: 0 auto; margin-bottom: 0px; margin-bottom: 200px; border-radius: 15px;}
#sidebarMenu .contact-block a {text-decoration: none; font-weight: 500; color: $orange;}

/** CONTACT **/
.mobile-header a {height: 60px; text-decoration: none;}
.mobile-header a.contact {display: flex; flex-direction: column; justify-content: center; align-items: center; width: 25%;}
.mobile-header a.contact svg {width: 40px; height: 40px;}
.mobile-header a.contact svg path {fill: #141414;}
.mobile-header a.contact span {color:#141414; font-size: 10px; text-align: center; font-family: $montserrat;}

/** BRAND LOGO **/
.mobile-header a.cwa {display: flex; justify-content: center; width: 50%;}
.mobile-header a.cwa svg {width: 120px;}
.mobile-header .top-cwa {fill: #141414;}

.sidebarIconToggle span {position: absolute; bottom: -14px; right: -4px; color:#141414; font-size: 10px; font-family: $montserrat;}


@media (min-width:769px) {
    .mobile-nav {display: none;}
}
@media (max-width:768px) {
    .top-contact {grid-template-columns: repeat(1, auto); height: auto; font-size: 14px; padding: 10px; box-shadow: 0 5px 10px -3px rgba(14,13,26,.12), 0 3px 16px 2px rgba(7,48,114,.12), 0 8px 12px 1px rgba(34,0,51,.04);}
    .top-contact .link a {color: $orange; margin: 1px 0;}
    .top-contact .link a svg path {fill: $orange;}
    .top-contact .block-right {margin-top: 5px;}
    .top-contact .block-right .schedules {margin: 0 20px 0 0;}
    .top-contact .block-right .social {width: 80px;}
    .top-contact .block-right .social a svg {height: 20px;}
    .top-contact .address {display: none;}
    .header {display: none;}
    .sidebarMenuInner li a {cursor: none;}
    .fb_dialog {z-index: 99!important;}
    .fb_dialog .fb_dialog_content iframe {z-index: 99!important;}
    .fb_iframe_widget iframe {height: calc(85% - 60px)!important; z-index: 100!important; top: calc(45%)!important;}
}
