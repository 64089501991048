@import '../variables/variables.scss';

body{ margin:0;  }
* {cursor: none;}
.pull-left{float:left}
.pull-right{float:right}
.clearfix:after,.clearfix:before{box-sizing:border-box; content:'';display:table}
.clearfix:after{box-sizing:border-box; clear:both;display:block}
#under-construction {display: flex; flex-direction: column-reverse; height: calc(100vh - 81px); background:#ffc600;}
#under-construction h1 {font-family: $chicle; color: #271e01; font-size: 77px; text-align: center; margin-bottom: 150px;}
.machine-wrap{ top:60%; left:50%; width:475px; height:145px; position:absolute; margin-top:-72.5px; margin-left:-237.5px; border-bottom:5px solid #271e01; }
.machine{ width:475px; height:145px; padding-bottom:20px; }
.machine .machine-roof, .machine .machine-main{ margin:auto; }
.machine-wrap .road .mud:before,
.machine-wrap .road .mud:after,
.machine .machine-roof .machine-silencer:before,
.machine .machine-roof .machine-silencer:after,
.machine .machine-body:before,
.machine .machine-body:after,
.machine .machine-main .machine-back:before,
.machine .machine-main .machine-back:after,
.machine .machine-main .machine-front:after{box-sizing:border-box; content:''; position:absolute; }
.machine .machine-roof{ width:80px; height:60px; bottom:-1px; position:relative; background:#ffc600; border:15px solid #271e01; border-bottom:none; }
.machine .machine-roof .machine-silencer{ width:14px; left:-50px; height:12px; bottom:-1px; position:absolute; background:#271e01; }
.machine .machine-roof .machine-silencer:before{box-sizing:border-box;  height:0; bottom:100%; width:inherit; border:6px solid #271e01; border-top:4px solid transparent; border-left:8px solid transparent; }
.machine .machine-roof .machine-silencer:after{box-sizing:border-box;  left:4px; width:5px; height:12px; bottom:100%; background:inherit; transform:skewY(-35deg); }
.machine .machine-roof .machine-silencer .machine-smoke{ left:-5px; width:16px; height:16px; bottom:20px; border-radius:50%; position:absolute; background:#271e01; transform:scale(0); animation:moveSmoke .5s linear infinite; }
.machine-main{ height:60px; width:185px; position:relative; }
.machine .machine-main .machine-body{ height:inherit; width:inherit; position:relative; background:#271e01; }
.machine .machine-main .machine-body:before{box-sizing:border-box;  left:-15px; width:30px; height:inherit; background:inherit; transform:skewX(-20deg); }
.machine .machine-main .machine-body:after{box-sizing:border-box;  bottom:0; right:15px; width:0px; height:41px; background:#ffc600; }
.machine .machine-main .machine-back{ bottom:-1px; width:75px; height:45px; position:absolute; }
.machine .machine-main .machine-back:before{box-sizing:border-box;  width:75px; height:45px; background:#ffc600; border-radius:50% 50% 0 0 / 85% 85% 0 0; }
.machine .machine-main .machine-back:after{box-sizing:border-box;  top:50%; left:50%; width:60px; height:60px; border-radius:50%; margin-top:-15px; margin-left:-30px; background:transparent; border:15px solid #271e01; }
.machine .machine-main .machine-front{ z-index:1; width:60px; height:60px; right:-20px; bottom:-27px; border-radius:50%; position:absolute; background:#271e01; border-top:2px solid #ffc600; }
.machine .machine-main .machine-front:after{box-sizing:border-box;  top:50%; left:50%; width:80px; height:20px; margin-top:-10px; margin-left:-40px; background:inherit; border:2px solid #ffc600; }
.machine-wrap .road{ right:0; bottom:0; height:15px; width:140px; overflow:hidden; position:absolute; }
.machine-wrap .road .mud-wrap{ width:160px; position:absolute; animation:moveRoad .4s linear infinite; }
.machine-wrap .road .mud{ bottom:0; width:40px; float:left; height:15px; position:relative; display:inline-block; }
.machine-wrap .road .mud:before, .machine-wrap .road .mud:after{box-sizing:border-box;  width:20px; height:20px; border-radius:4px; background:#271e01; transform:rotate(45deg); }
.machine-wrap .road .mud:before{box-sizing:border-box;  left:0; bottom:-17px; }
.machine-wrap .road .mud:after{box-sizing:border-box;  right:0; bottom:-20px; }
.machine-wrap .machine-roof,
.machine-wrap .machine-body,
.machine-wrap .machine-silencer,
.machine-wrap .machine-back:before,
.machine-wrap .machine-front{box-sizing:border-box;  animation:upDown .6s linear infinite; }
.machine-wrap .machine-silencer{ animation-duration:.4s; }
.machine-wrap .machine-front{ animation-duration:.3s; }
.machine-wrap .machine-back{ animation:upDown .8s linear infinite; }
@keyframes moveRoad{
	0%{ right:-40px; }
	100%{ right:0; }
}
@keyframes moveSmoke{
	0%{ opacity:0; transform:scale(0) translate(0); }
	60%{ transform:scale(.5) translate(0); }
	100%{ opacity:.6; transform:scale(1) translate(-10px,-40px); }
}
@keyframes upDown{
	0%{ transform:translateY(0); }
	50%{ transform:translateY(2px); }
	100%{ transform:translateY(0); }
}


/** PAGE 404 **/
#page_404 {display: flex; flex-direction: column; justify-content: flex-start; align-items: center; height: calc(100vh - 81px);}
#page_404 h1 {font-family: 'Dela Gothic One', cursive; font-size: 44px; font-weight: 900; line-height: 44px;}
#page_404 img {height: 50vh;}
#page_404 h2 {font-family: "Truculenta", sans-serif; font-size: 36px; text-transform: uppercase; line-height: 36px; margin: 0px 0px 20px;}
#page_404 a {background-color: #000; text-align: center; text-decoration: none; font-family: "Montserrat", sans-serif; font-size: 24px; font-weight: 700; padding: 1.5% 2%; color: #fff; display: flex; justify-content: center; align-items: center; line-height: 25px; border: 2px solid #000; border-radius: 25px;}
#page_404 a:hover {border: 2px solid #000; box-shadow: inset 400px 0 0 0 #f4ca02; color: #000;}

@media (max-width:768px) {
	#under-construction {height: calc(100vh - 60px);}
	#under-construction h1 {margin-bottom: 400px;}

	#page_404 {height: calc(100vh - 60px);}
	#page_404 h1 {text-align: center;}
	#page_404 img { height: 40vh; }
	#page_404 h2 {font-size: 27px; text-align: center;}
	#page_404 a {font-size: 18px; padding: 1.5% 5%;}
}
