@import '../variables/variables.scss';

.block_orientation, .disabled {display: none;}
.text-center {text-align: center!important;}

@media (max-width: 980px) {
  .block_orientation {font-size: 2.6vw; color: #000;}
}
@media (min-width:380px) and (max-width: 820px) and (orientation: landscape) {
  .block_orientation {background-color: #141414; color: $white; z-index: 777777777; display: block; padding: 1em 0; font-size: 3.5vw; position: fixed; width: 100vw; height: 100vh; left: 0; text-align: center; top: 0;}
  .block_orientation svg {width: 200px; margin-bottom: 0rem!important;}
//   .block_orientation .st0{fill:none;stroke:#A1C655;stroke-width:0.8339;stroke-miterlimit:10;}
//   .block_orientation .st1{fill:#BA1C79;}
//   .block_orientation .st2{fill:#A9968B;}
//   .block_orientation .st3{fill:#00728A;}
  .block_orientation svg.rotate {width: 60px;}
  .px-4 {width: 80%; font-family: $oswald; font-weight: 300; font-size: 16px; line-height: 36px; margin: 0 auto;}
}





/** RESPONSIVE DESIGN **/

@media (max-width: 768px) {
  .block_orientation p {margin-top: 0};
  .block_orientation svg {margin: 0 auto;}
}
