@import '../variables/variables.scss';

/** GENERAL **/
footer {display: flex; flex-direction: column; background-color: #282828; color: $white; padding-top: 20px;}
.footer-top {display: flex; height: 710px;}

/** COPYRIGHT **/
.footer-down {display: flex; justify-content: space-between; height: 40px; margin: 0px 70px 5px 70px; font-family: $oswald; font-size: 21px; color: $white;}
.footer a {text-decoration: none; color: $white;}
.footer a:hover {color: $yellow;}

/** LEFT COLUMN **/
.footer-left {display: flex; flex-direction: column; width: 55%; height: 600px; padding: 5%;}
.footer-left-top {height: 100px; font-size: 36px; font-weight: 700; border-bottom: 1px solid $white; padding-bottom: 77px;}
.footer-left-middle {font-weight: 700; border-bottom: 1px solid $white; padding-bottom: 5%; height: 450px;}
.footer-left-down {height: 50px;}
.brand-name {font-family: $londrinaShadow; font-size: 77px; margin-top: 36px;}
.brand-slogan {font-family: $chicle; color: $yellow; font-size: 27px; letter-spacing: 5px; font-style: italic; margin-top: -80px; padding-left: 240px;}
.footer-left-middle {display: flex; height: 70%;}
.footer-left-middle-left, .footer-left-middle-right {width: 45%; padding: 5%;}
.footer-left-middle-left p {font-family: $abril; font-weight: 400; line-height: 1.5; margin: 0 0 15px; font-size: 44px;}
.footer-left-middle-left div {font-family: $montserrat; font-size: 24px; font-weight: 400;}
.footer-left-middle-left .block-schedule {width: max-content; margin: 50px 0 -50px 0;}
.footer-left-middle-left .block-schedule .schedules {color: #f4ca02; font-size: 1.6vw; font-weight: 600; line-height: 2.4vw;}
.footer-left-down {display: flex; justify-content: space-around; padding-top: 3%;}
.footer-left-down div {display: flex; justify-content: center; align-items: center;}
.footer-left-down .phone-link {width: 45%;}
.footer-left-down .mail-link {width: 55%;}
.footer-left-down svg {fill: #6B6B6B; width: 25px; transform: skew(-15deg, 0deg); height: 25px;}
.footer-left-down a {font-family: $montserrat; color: #6B6B6B; font-size: 16px; font-weight: 600; letter-spacing: 1.2px; text-decoration: none; margin-left: 5%;}
.footer-left-down a:hover {color: $yellow;}

/** RIGHT COLUMN **/
.footer-right {width: 50%; padding: 5% 2% 4% 2%;} 

/** RIGHT **/
.form-block {padding: 2%; border: 1px solid $white;}
.form-rounded {width: 399px; border-style: solid; border-width: 1px; border-color: #F7F7F7; background-color: transparent; border-radius: 30px; padding-bottom: 36px; position: relative; margin-right: -10000px; margin-left: 50%; left: -201px;}
.form-rounded form {padding: 30px;}
form {display: flex; flex-wrap: wrap; justify-content: flex-start;}
.form-half-1 {display: flex; justify-content: center; width: 48%; margin-top: 2%; margin-right: 1%;}
.form-half-2 {display: flex; justify-content: center; width: 48%; margin-top: 2%; margin-left: 1%;}
.form-full {display: flex; justify-content: center; width: 98%; margin-top: 2%;}
.form-half-1 input, .form-half-2 input, .form-full input, .form-full textarea {width: 100%; background-color: #232323; border: none;}
.form-half-1 input[type="text"], .form-half-2 input[type="text"], .form-full input[type="email"], .form-full input[type="tel"] {height: 50px;}
.form-half-1 input::placeholder, .form-half-2 input::placeholder {color: #7d7676; padding-left: 5%;}
.form-full textarea {color: #7d7676;}
.form-full input::placeholder {color: #7d7676; padding-left: 2%;}
.form-btn {margin-top: 5%;}
.form-btn input[type="submit"] {border: 1px solid $white; background-color: #000; color: $white; padding: 30% 50%;}
.form-full .fa-mobile-alt {padding-top: 4%; padding-left: 2%; margin-right: -4%; z-index: 10; color: #6b6b6b;}
input[type="tel"] {padding-left: 5%;}
input[type="tel" i] {padding-left: 5%;}
.form-block-test {width: 0.01px; position: relative; margin-top: 32px; margin-left: 50%; left: -170px;} .label-block {width: 157px; height: 20px; border-width: 0px; border-color: transparent; background-color: transparent; color: #6B6B6B; text-align: left; line-height: 36px; font-family: $montserrat; font-weight: 600; position: relative;}
.input-block input {font-family: $montserrat; font-size: 12px; font-weight: 300; color: $orange; width: 340px; height: 27px; margin-bottom: 27px; background-color: transparent; border: none; border-bottom: 1px solid #cccccc;}
.input-block input:focus, .textarea-block textarea:focus {font-family: $montserrat; font-size: 12px; font-weight: 300; color: $orange; outline: none; background-color: transparent;}
.textarea-block textarea {font-family: $montserrat; font-size: 12px; font-weight: 300; color: $orange; width: 340px; height: 120px; margin-bottom: 36px; background-color: transparent; border: none; border-bottom: 1px solid #cccccc;}
.input-block input:hover, .textarea-block textarea:hover {font-family: $montserrat; font-size: 12px; font-weight: 300; color: $orange; background-color: transparent;}
.input-block input::placeholder, .textarea-block textarea::placeholder {font-family: $montserrat; font-style: italic; font-size: 15px; color: #6B6B6B;}
.input-block input:-webkit-autofill, .input-block input:-webkit-autofill:hover, .input-block input:-webkit-autofill:focus, .input-block textarea:-webkit-autofill, .input-block textarea:-webkit-autofill:hover, .input-block textarea:-webkit-autofill:focus, .input-block select:-webkit-autofill, .input-block select:-webkit-autofill:hover, .input-block select:-webkit-autofill:focus { -webkit-text-fill-color: $orange; -webkit-box-shadow: none; transition: background-color 5000s ease-in-out 0s; }
.button-form button[type="submit"] {width: 130px; height: 36px; background-color: #FFFFFF; border-radius: 2px; color: #6B6B6B; text-align: center; line-height: 14px; font-family: $montserrat; font-size: 15px; font-weight: 600; position: relative; padding: 8px; border: 1px solid #6B6B6B;}
.button-form button[type="submit"]:hover {background-color: $yellow; color: #242424;}


/** RESPONSIVE DESIGN **/

@media (max-width: 768px) {
    footer {padding-top: 0;}
    .footer-top {flex-direction: column; height: auto;}
    .footer-left {width: 90%; height: auto;}
    .footer-left-top {padding-bottom: 36px;}
    .brand-name {font-size: 45px;}
    .brand-slogan {padding: 0; width: 100%; font-size: 20px; text-align: right; margin-top: -45px;}
    .footer-left-middle {height: auto;}
    .footer-left-middle-left {width: 90%; text-align: center;}
    .footer-left-middle-left p {font-size: 36px;}
    .footer-left-middle-left div {font-size: 18px;}
    .footer-left-middle-left .block-schedule {width: 100%; margin: 40px 0 20px;}
    .footer-left-middle-left .block-schedule .schedules {font-size: 4vw; line-height: 8vw;}
    .footer-left-middle-right {display: none;}
    .footer-left-down {flex-direction: column; height: auto; padding-top: 36px;}
    .footer-left-down svg {display: none;}
    .footer-left-down .phone-link, .footer-left-down .mail-link {width: 100%;}
    .footer-left-down .phone-link {padding-bottom: 20px;}
    .footer-left-down a {margin-left: 0;}
    .footer-right { display: none; /*display: flex; justify-content: center; width: 100%; padding: 0; margin-top: 21px;*/}
    .form-rounded {left: 0; width: 90%; margin: 0% 10%; padding-bottom: 8px;}
    .form-line {width: 100%;}
    .input-block input {width: 100%; margin-bottom: 10px;}
    .textarea-block textarea {width: 100%;}
    .footer-down {flex-direction: column; justify-content: center; font-size: 18px; text-align: center; padding-top: 36px; padding-bottom: 100px; margin: 0 auto;}
    .footer-down .legals {padding-top: 10px;}
}
@media (min-width:1900px) {
    .footer-top {display: flex; justify-content: space-between; width: 55%; margin: 0 auto; height: auto;}
    .footer-right {width: 40%;}
    .footer-down {width: 55%; margin: 0 auto 40px;}
    .footer-left-middle-left div {line-height: 40px;}
    .footer-left-middle-left .block-schedule .schedules {font-size: 1vw; line-height: 1.5vw;}
    .input-block input, .textarea-block textarea {font-size: 15px;}
    .input-block input:focus, .textarea-block textarea:focus {font-size: 15px;}
    .input-block input:hover, .textarea-block textarea:hover {font-size: 15px;}
}
