/** FONT NAME **/
$abril: 'Abril Fatface', cursive;
$chicle:'Chicle', cursive;
$galada: 'Galada', cursive;
$londrinaShadow: 'Londrina Shadow', cursive;
$montserrat: 'Montserrat', sans-serif;
$truculenta: 'Truculenta', sans-serif;
$oswald: 'Oswald', sans-serif;

/** COLORS **/
$black: #000;
$white: #fff;
$yellow: #f4ca02; // voir la vraie couleur ??
$orange: #ff8300; // voir la vraie couleur ??
$blue: #0000ff;
$red: #ff0000;
$green: #58a016;
$grey: #282828;
$light-grey: #f5f5f5;

$strategy-color: #f03a3acf;
$creation-color: #ff7f2be0;
$print-color: #58a016c7;
$identity-color: #3272fcc7;
$seo-color: #f3c130;
$community-color: #b73feeb8;
