@import '../variables/variables.scss';

img {cursor: none;}
.v-cursor {z-index: 99999999; position: fixed; background-color: #ff7f2b85; left: 0; top: 0; height: 18px; width: 18px; border-radius: 50%; pointer-events: none; opacity: 0; transform: translate(-50%, -50%);}
.v-cursor.link {height: 50px; width: 50px; background-color: #f4cc0244; border: 3px solid rgba(224, 222, 255, 0);}
.v-cursor.button {height: 60px; width: 60px; border: 3px solid #ffd600;}
.v-cursor.img {height: 80px; width: 80px; background-color: #ffd600D9;}
.v-cursor.img::after {opacity: 1; transform: translate(-50%, -50%) scale(1);}
.v-cursor::after {content: 'Voir'; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) scale(0.5); font-family: $montserrat; color: #17171c; font-size: 16px; font-weight: 600; opacity: 0; transition: 0.5s;}
.v-dot {z-index: 99999999; position: fixed; background-color: transparent; left: 0; top: 0; height: 5px; width: 5px; border-radius: 50%; pointer-events: none; opacity: 0; transition: 0s, opacity .5s, background-color .4s; transform: translate(-50%, -50%);}
.v-dot.link {background-color: rgba(255, 255, 255, 0);}
.v-dot.button {background-color: rgba(255, 255, 255, 0);}

/** RESPONSIVE **/
@media (max-width:1199px) {
    .v-cursor {display: none;}
  }
@media (max-width:768px) {
    cursor: auto;
    .v-cursor {display: none;}
    .v-cursor.link {display: none;}
    .v-cursor.button {display: none;}
    .v-cursor.img {display: none;}
    .v-cursor.img::after {display: none;}
    .v-cursor::after {display: none;}
    .v-dot {display: none;}
    .v-dot.link {display: none;}
    .v-dot.button {display: none;}
}
@media (min-width:1900px) {
  .v-cursor.img {width: 100px; height: 100px;}
  .v-cursor::after {font-size: 18px;}
}
